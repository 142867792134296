import { request } from "@/request/http";

/**
 * @name: 学校管理接口
 * @author: LXY
 * @date: 2022-02-28 
 */
export default {
    // 获取学校列表
    getSpitKeyWordsList: (data) => {
        return request("POST", `/index.php/adminSpit/getSpitKeyWordsList`, data);
    },
    // 添加/修改学校信息
    updateSpitKeyword: (data) => {
        return request("POST", `/index.php/adminSpit/updateSpitKeyword`, data);
    },
    // 获取修改学校信息
    getSpitKeywordInfo: (data) => {
        return request("POST", `/index.php/adminSpit/getSpitKeywordInfo`, data);
    },
    // 删除学校
    delSpitKeyword: (data) => {
        return request("POST", `/index.php/adminSpit/delSpitKeyword`, data);
    },
    // 上传学校logo
    updateSchoolLogo: (data) => {
        return request("POST", `/index.php/adminSchool/updateSchoolLogo`, data);
    },
    // 获取虚拟实验列表
    getSchoolExpList: (data) => {
        return request("POST", `/index.php/adminSchool/getSchoolExpList`, data);
    },
    // 授权学校虚拟实验
    grantSchoolExp: (data) => {
        return request("POST", `/index.php/adminSchool/grantSchoolExp`, data);
    },
};