import { Message } from "element-ui"; //项目已经全局引入element的话可以不单独引入
import router from "@/router";
import { encryptCode, decryptCode } from "@/utils/function";
import { uploadSchoolLogoFilePath, request_sign } from "@/utils/const";
import API from '@/api/manager/keyWords/keyWords.js';
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
export default {
    // 页面名称
    name: "keyWords",
    inject: ['reload'],
    data() {
        return {
            keywordInfo: {
                cid: '',
                content: '',
                exp_id: '',
                id: '',
                keyword: '',
                step: '',
            },
            cid: '',
            step: '',
            exp_id: '',
            input_key: '',
            keyword: '',
            sign: '',
            infoVisible: false,
            infoTitle: '',
            // 列表数据
            schoolList: [],
            // 数据总数
            schoolListTotal: 0,
            // 根据内容搜索
            input: "",
            // 当前页码数
            page: 1,
            // 当前一页显示多少条数据
            pageSize: 10,
        }
    },
    /**
     * @name: 生命周期创建
     * @desc: 页面创建时获取数据
     * @author: lxy
     * @date: 20210908
     */
    created() {
        let self = this;
        if (this.$router.currentRoute.query.params) {
            let params = JSON.parse(decryptCode(this.$router.currentRoute.query.params));
            self.page = Number(params?.page);
            self.input = params?.search;
        }
        self.getData();
    },
    methods:
    {
        /**
         * @name: 分页序号递增
         * @author: lxy
         * @date: 20220507
         */
        indexMethod(index) {
            let currentPage = this.page; // 当前页码
            let pageSize = this.pageSize; // 每页条数
            return (index + 1) + (currentPage - 1) * pageSize; // 返回表格序号
        },
        /**
         * @name: 添加关键词
         * @author: lxy
         * @date: 2020-12-29 16:38:39
         */
        addSchool(row) {
            this.keywordInfo.cid = '';
            this.keywordInfo.exp_id = '';
            this.keywordInfo.keyword = '';
            this.keywordInfo.step = '';
            this.keywordInfo.id = '';
            this.keywordInfo.content = '';
            this.infoTitle = '添加关键词'
            this.infoVisible = true;
            this.sign = 'add';
        },
        /**
         * @name: 点击分页
         * @author: lxy
         * @date: 2020-12-29 16:38:39
         */
        handleCurrentChange(val) {
            const self = this;
            self.page = val;
            self.getData();
        },
        /**
         * @name: 修改关键词
         * @author: lxy
         * @date: 2020-12-29 16:38:39
         */
        updatePic(row) {
            const self = this;
            self.sign = 'update';
            let str = encryptCode({
                id: row.id
            })
            let data = { sign: request_sign, params: str }
            nprogressStart();
            self.$Loading.show()
            API.getSpitKeywordInfo(data)
                .then((result) => {
                    nprogressClose();
                    self.$Loading.hide()
                    if (result.code > 0) {
                        self.infoVisible = true;
                        self.infoTitle = '修改关键词 / ' + row.exp_id
                        self.keywordInfo = result.info;
                    }
                    else {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
                .catch(() => {
                    nprogressClose();
                    self.$Loading.hide()
                })
        },
        /**
         * @name: 确认更新关键词信息
         * @author: LXY
         * @date: 2021-03-01
         */
        confirmUpdate() {
            const self = this;
            let str = encryptCode({
                ...self.keywordInfo
            })
            console.log({...self.keywordInfo});
            
            let data = { sign: request_sign, params: str }
            // 开启loading
            nprogressStart();
            self.$Loading.show()
            API.updateSpitKeyword(data)
                .then((result) => {
                    // 关闭loading
                    nprogressClose();
                    self.$Loading.hide()
                    if (result.code > 0) {
                        this.infoVisible = false;
                        this.getData()
                    }
                    else {
                        this.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
                .catch(() => {
                    nprogressClose();
                    self.$Loading.hide()
                })
        },
        /**
         * @name: 获取列表
         * @author: lxy
         * @date: 20210908
         */
        getData() {
            try {
                let self = this;
                // 请求参数
                let str = encryptCode({
                    search: self.input,
                    page: self.page,
                    pagesize: self.pageSize
                })
                let data = { sign: request_sign, params: str }
                nprogressStart();
                self.$Loading.show();
                self.$router.push({
                    path: "/manager/keyWords",
                    query: {
                        params: str
                    },
                });
                // 获取列表请求
                API.getSpitKeyWordsList(data)
                    .then((result) => {
                        nprogressClose();
                        self.$Loading.hide();
                        if (result.code > 0) {
                            self.schoolList = result.list
                            self.schoolListTotal = result.total - 0
                        }
                        else {
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                displayTime: 1500
                            })
                        }
                    })
            }
            catch (error) {
                // Message.error('系统错误') 
            }
        },
        /**
         * @name: 删除
         * @author: lxy
         * @date: 20210908
         */
        deletePic(row) {
            try {
                let self = this;
                // 请求参数
                let str = encryptCode({
                    id: row.id
                })
                let data = { sign: request_sign, params: str }
                // 删除请求
                self.$confirm(`确认要删除${row.keyword}吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    API.delSpitKeyword(data)
                        .then((result) => {
                            if (result.code > 0) {
                                // Message.success('删除成功');
                                self.getData();
                                self.$Tips({
                                    // 消息提示内容
                                    message: result.msg,
                                    // 消息提示类型（success-成功,warning-警告/失败）
                                    messageType: 'success',
                                    displayTime: 1500
                                })
                            }
                            else {
                                self.$Tips({
                                    // 消息提示内容
                                    message: result.msg,
                                    // 消息提示类型（success-成功,warning-警告/失败）
                                    messageType: 'warning',
                                    displayTime: 1500
                                })
                            }
                        })
                }).catch(() => {
                    // Message.info('已取消');        
                });
            }
            catch (error) {
                // Message.error('系统错误');
            }
        },
        /**
         * @name: 添加关键词
         * @author: lxy
         * @date: 20210908
         */
        confirmAdd() {
            try {
                let self = this;
                // 请求参数
                let data = {
                    id: self.schoolID,
                    pic: self.schoolPic
                };
                // 添加关键词请求
                API.updateSchoolLogo(data)
                    .then((result) => {
                        if (result.code > 0) {
                            self.logoVisible = false;
                            self.getData();
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'success',
                                displayTime: 1500
                            })
                        }
                        else {
                            // Message.error('系统错误')
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                displayTime: 1500
                            })
                        }
                    })
            }
            catch (error) {
                // Message.error('系统错误') 
            }
        },
        /**
         * @name: 根据搜索条件,获取数据
         * @author: lxy
         * @date: 20210908
         */
        handleSearchColleague() {
            let self = this;
            self.page = 1;
            self.$router.push({
                path: "/manager/keyWords",
                query: {
                    search: self.input,
                    page: self.page
                }
            });
            self.getData()
        },
    }
};