<template >
  <div class="keyWords">
    <div class="logButton clearFix">
      <!-- <div class="export">
        <el-button class="confirmButton" @click="addSchool">添加关键词</el-button>
      </div> -->
      <div class="search">
        <!-- 搜索题目 -->
        <div class="searchContent">
          <el-input
            placeholder="请输入虚拟实验id或关键词"
            v-model="input"
            clearable
            @clear="handleSearchColleague"
            class="input-with-select"
          ></el-input>
        </div>
        <el-button class="confirmButton" @click="handleSearchColleague">搜索</el-button>
      </div>
    </div>
    <!-- 列表 -->
    <el-card>
      <el-table ref="multipleTable" :data="schoolList" tooltip-effect="dark" style="width: 100%">
        <!-- 序号 -->
        <el-table-column type="index" :index="indexMethod" label="序号" width="100"></el-table-column>
        <!--关键词  -->
        <el-table-column prop="keyword" label="关键词"></el-table-column>
        <!--exp_id  -->
        <el-table-column prop="exp_id" min-width="50" label="exp_id"></el-table-column>
        <!--关卡  -->
        <el-table-column prop="cid" min-width="40" label="关卡"></el-table-column>
        <!-- 统计 -->
        <el-table-column prop="useNum" min-width="40" label="统计"></el-table-column>
        <!--step  -->
        <el-table-column prop="step" label="step"></el-table-column>
        <!-- 操作-->
        <el-table-column min-width="120" label="操作">
          <template slot-scope="scope">
            <!-- <div class="operatorItem floatLeft" @click="addSchool(scope.row)">添加</div> -->
            <div class="operatorItem floatLeft" @click="updatePic(scope.row)">修改</div>
            <div class="operatorItem floatLeft" @click="deletePic(scope.row)">删除</div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        background
        :page-size="pageSize"
        layout="prev, pager, next"
        :total="schoolListTotal"
      ></el-pagination>
    </el-card>
    <el-dialog :title="infoTitle" :visible.sync="infoVisible" width="30%">
      <span class="dialog-span">
        <el-form label-position="left" label-width="90px">
          <!-- <el-form-item label="关键词" v-if="sign == 'add'">
            <el-input v-model="addkeyWords" type="textarea" autosize></el-input>
          </el-form-item>-->
          <el-form-item label="关键词">
            <el-input v-model="keywordInfo.keyword"></el-input>
          </el-form-item>
           <!-- <el-form-item label="虚拟实验id">
            <el-input v-model="keywordInfo.exp_id"></el-input>
          </el-form-item>
          <el-form-item label="关卡">
            <el-input v-model="keywordInfo.cid"></el-input>
          </el-form-item>
          <el-form-item label="step">
            <el-input v-model="keywordInfo.step"></el-input>
          </el-form-item> -->
         
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancelButton" @click="infoVisible = false">取 消</el-button>
        <el-button class="confirmButton" @click="confirmUpdate">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 引入试题列表js
import keyWords from "@/assets/js/manager/keyWords/keyWords.js";
export default {
  ...keyWords
};
</script>

<style>
.keyWords .confirmButton {
  padding: 0 0.7vw !important;
  height: 2.0833vw !important;
  background-color: #0d5570 !important;
  color: #ffffff !important;
  border-radius: 0.2083vw !important;
  font: normal normal normal 0.8333vw Source Han Sans CN !important;
}
.keyWords .el-input__inner {
  font-size: 0.8333vw;
  height: 2.0833vw;
}
.keyWords .search .el-input,
.keyWords .search .el-select {
  width: 15vw !important;
}
.keyWords .cancelButton {
  padding: 0 0.7vw !important;
  height: 2.0833vw !important;
  background-color: #ffffff !important;
  color: #000000 !important;
  border: 0.0521vw solid #cccccc !important;
  border-radius: 0.2083vw !important;
  font: normal normal normal 0.8333vw Source Han Sans CN !important;
}
.keyWords .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #0d5570;
  color: #fff;
}
.keyWords .el-table__body-wrapper {
  height: 67vh;
  overflow: scroll;
  position: relative;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: scroll;
}
.keyWords .el-dialog {
  /* padding: 1.5625vw 1.0417vw;
    padding-top: 0.2604vw;
    color: #606266;
    font-size: 0.7292vw;
    word-break: break-all;
    padding-bottom: 1.5625vw; */
  border-radius: 0.625vw !important;
}
.keyWords .el-table__body-wrapper::-webkit-scrollbar {
  display: none;
}
.keyWords .el-upload {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.keyWords .avatar-uploader {
  /* text-align:center; */
}
.keyWords .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.keyWords .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.keyWords .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.keyWords .grantBox .el-table__body-wrapper {
  height: 25vw;
  overflow: scroll;
  position: relative;
}
.keyWords .grantBox .el-checkbox__input.is-checked .el-checkbox__inner,
.keyWords .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #0d5570;
  border-color: #0d5570;
}
.keyWords .grantBox .el-table__body-wrapper::-webkit-scrollbar {
  display: none;
}
</style>
<style lang="scss" scoped>
// 引入试题列表组件css
@import "@/assets/css/manager/keyWords/keyWords.scss";
</style>